import { createContext, useContext, useState } from "react";
import AuthenticationContext from "../authentication/AuthenticationContext";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import { Bounce, toast } from "react-toastify";

const PackageNewContext = createContext({})

export const PackageNewProvider = ({children}) => {
    const {authenticationToken} = useContext(AuthenticationContext)
    const {idGroupage} = useParams()
    const navigate = useNavigate()
    const groupId = idGroupage

    const [label, setLabel] = useState('')
    const [weight, setWeight] = useState(0.0)
    const [senderName, setSenderName] = useState('')
    const [senderPhone, setSenderPhone] = useState('')
    const [receiverName, setReceiverName] = useState('')
    const [receiverPhone, setReceiverPhone] = useState('')

    const handleNewPackage = (e) => {
        e.preventDefault()

        const data = {
            label, weight, senderName, senderPhone, receiverName, receiverPhone, groupId
        }

        console.log(data)

        api.post('admin/groupage/parcel', data, {
            headers : {
                Authorization : authenticationToken
            }
        }).then((res) => {
            toast.success("Paquet ajouté avec succès", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })

            navigate(`/packages/${res.data.id}/infos`)
        }).catch((err) => {
            toast.error("Une erreur est survenue lors de l'ajout du paquet. Veuillez reéssayer !!!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
            console.log(err)
        })
    }

    return (
        <PackageNewContext.Provider
            value={
                {
                    handleNewPackage,
                    label, setLabel,
                    weight, setWeight,
                    senderName, setSenderName,
                    senderPhone, setSenderPhone,
                    receiverName, setReceiverName,
                    receiverPhone, setReceiverPhone,
                }
            }
        >
            {children}
        </PackageNewContext.Provider>
    )
}

export default PackageNewContext