import React, { useContext } from 'react'
import GroupageEditContext from '../../context/groupage/GroupageEditContext'

const GroupageEdit = () => {
    const {
        handleEditGroupage,
        id,
        expectedDepartureDay, setExpectedDepartureDay,
        expectedDurationInDays, setExpectedDurationInDays,
        discount, setDiscount,
        status, setStatus
    } = useContext(GroupageEditContext)

  return (
    <div className="card">
        <div className="card-body">
            <h5 className="card-title fw-semibold mb-4">Modification du groupage</h5>
            <div className="card">
                <div className="card-body">
                    <form className="row" onSubmit={(e) => handleEditGroupage(e)}>
                        <div className="mb-3 ">
                            <label htmlFor="departure" className="form-label">Départ</label>
                            <input type="date" className="form-control" id="departure" value={expectedDepartureDay.slice(0, 10)} onChange={(e) => setExpectedDepartureDay(e.target.value)} aria-describedby="destination" />
                        </div>
                        
                        <div className="mb-3 ">
                            <label htmlFor="duration" className="form-label">Durée</label>
                            <input type="number" min={1} className="form-control" id="duration" value={expectedDurationInDays} onChange={(e) => setExpectedDurationInDays(e.target.value)} aria-describedby="duration" placeholder="1" />
                        </div>
                        <div className="mb-3 ">
                            <label htmlFor="discount" className="form-label">Reduction</label>
                            <input type="number" min={1} className="form-control" id="discount" value={discount} onChange={(e) => setDiscount(e.target.value)} aria-describedby="discount" placeholder="1" />
                        </div>
                        <div className="mb-3 ">
                            <label htmlFor="statut" className="form-label">Statut</label>
                            <select className="form-select" id="statut" value={status} onChange={(e) => setStatus(e.target.value)} aria-describedby="status">
                                <option value="CREATED">CREATED</option>
                                <option value="IN_TRANSIT">IN_TRANSIT</option>
                                <option value="CUSTOM_CLEARANCE">CUSTOM_CLEARANCE</option>
                                <option value="ARRIVED">ARRIVED</option>
                            </select>
                        </div>

                        <div className="d-flex gap-3 p-3 mt-3 border-top">
                            <a href={`/groupages/${id}/infos`} type="button" className="btn btn-danger">Annuler</a>
                            <button type="submit" className="btn btn-secondary">Modifier</button>
                        </div> 
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default GroupageEdit