import React from 'react'
import ServiceHeader from '../../components/header/ServiceHeader'
import ServiceTableHead from '../../components/table_head/ServiceTableHead'
import ServiceTableBody from '../../components/table_body/ServiceTableBody'
import Pagination from '../../components/pagination/Pagination'

const ServiceList = () => {
  return (
    <>
        <div className='row'>
            <div className="col-lg-12 d-flex align-items-stretch">
                <div className="card w-100">
                    <div className="card-body p-4">
                        <ServiceHeader/>
                        <div className="table-responsive">
                            <table className="table text-nowrap mb-0 align-middle text-center">
                                <ServiceTableHead />
                                <ServiceTableBody />
                            </table>
                        </div>
                        <Pagination />
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ServiceList