import React from 'react'

const PackageTableHead = () => {
  return (
    <thead className="text-dark fs-4">
        <tr>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Label</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Poids</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Prix</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Paiement</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Statut</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Date de création</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Actions</h6>
            </th>
        </tr>
    </thead>
  )
}

export default PackageTableHead