import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import PackageContext from '../../context/package/PackageContext'

const PackageHeader = () => {
    const {idGroupage} = useParams()
    const {groupage} = useContext(PackageContext)
  return (
    <div className='d-flex justify-content-between align-items-center border-bottom p-3'>
        <h5 className="card-title fw-semibold mb-4">Liste des paquets</h5>
        {groupage.status === 'CREATED' && <a href={`/groupages/${idGroupage}/packages/new`} className='btn btn-primary'><i className="ti ti-file-plus me-2"></i> Nouveau paquet</a>}
    </div>
  )
}

export default PackageHeader