import React from 'react'

const GroupageHeader = () => {
  return (
    <div className='d-flex justify-content-between align-items-center'>
        <h5 className="card-title fw-semibold mb-4">Liste des groupages</h5>
    </div>
  )
}

export default GroupageHeader