import { createContext, useContext, useEffect, useState } from "react";
import AuthenticationContext from "../authentication/AuthenticationContext";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import { Bounce, toast } from "react-toastify";

const GroupageNewContext = createContext({})

export const GroupageNewProvider = ({children}) => {
    const {authenticationToken} = useContext(AuthenticationContext)
    const {idService} = useParams()
    const navigate = useNavigate()

    const [expectedDepartureDay, setExpectedDepartureDay] = useState('')
    const [expectedDurationInDays, setExpectedDurationInDays] = useState(0)
    const [discount, setDiscount] = useState(0)

    const [service, setService] = useState({})

    const handleNewGroupage = (e) => {
        e.preventDefault()

        const data = {
            'expectedDepartureDay' : expectedDepartureDay,
            'expectedDurationInDays' : expectedDurationInDays,
            'discount' : discount,
            'serviceTypeId' : idService
        }

        api.post(`admin/groupage/group`, data, {
            headers : {
                Authorization : authenticationToken
            }
        }).then((res) => {
            toast.success("Groupage ajouté avec succès", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            }) 

            navigate(`/groupages/${res.data.id}/infos`)
        }).catch((err) => {
            toast.error("Une erreur est survenue lors de la création du groupage. Veuillez reéssayer !!!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
            console.log(err)
        })
    }

    useEffect(() => {
        api.get(`admin/groupage/service?id=${idService}`, {
            headers : {
                Authorization : authenticationToken
            }
        }).then((res) => {
            setService(res.data)
        }).catch((err) => {
            toast.error("Une erreur est survenue lors de la recupération du service. Veuillez reéssayer !!!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        })
    }, [authenticationToken, idService])

    return (
        <GroupageNewContext.Provider
            value={
                {
                    handleNewGroupage,
                    expectedDepartureDay, setExpectedDepartureDay,
                    expectedDurationInDays, setExpectedDurationInDays,
                    discount, setDiscount,
                    idService,service
                }
            }
        >
            {children}
        </GroupageNewContext.Provider>
    )
}

export default GroupageNewContext