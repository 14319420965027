import React, { useContext } from 'react'
import GroupageContext from '../../context/groupage/GroupageContext'

const GroupageTableBody = () => {
    const {groupages} = useContext(GroupageContext)
  return (
    <tbody>
        {
            groupages.map(
                (groupage) =>
                    <tr key={groupage.id}>
                        <td className="border-bottom-0 text-start">
                            <h6 className="fw-semibold mb-0">{groupage.label}</h6>
                        </td>
                        <td className="border-bottom-0">
                            <h6 className="fw-semibold mb-1">{groupage.destination}</h6>
                        </td>
                        <td className="border-bottom-0">
                            <h6 className="mb-0 fw-semibold">{groupage.expectedDepartureDay.slice(0, 10)}</h6>
                        </td>
                        <td className="border-bottom-0">
                            <h6 className="mb-0 fw-semibold">{groupage.expectedDurationInDays} jours</h6>
                        </td>
                        <td className="border-bottom-0">
                            <h6 className="fw-semibold mb-0 fs-4">{groupage.price}</h6>
                        </td>
                        <td className="border-bottom-0">
                            <div className="d-flex justify-content-center align-items-center gap-2">
                                <span className="badge bg-success rounded-3 fw-semibold">{groupage.status}</span>
                            </div>
                        </td> 
                        <td className="border-bottom-0 d-flex justify-content-evenly gap-2">
                            <a href={`/groupages/${groupage.id}/infos`} className='btn-secondary btn'><i className="ti ti-eye"></i></a>
                            <a href={`/groupages/${groupage.id}/edit`} className='btn-warning btn'><i className="ti ti-edit"></i></a>
                        </td>
                    </tr>
            )
        }
    </tbody>
  )
}

export default GroupageTableBody