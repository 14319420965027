import { Route, Routes } from "react-router-dom";
import Login from "./pages/login/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/header/Header";
import Home from "./pages/home/Home";
import { LoginProvider } from "./context/authentication/LoginContext";
import { useContext } from "react";
import AuthenticationContext from "./context/authentication/AuthenticationContext";
import Sidebar from "./components/sidebar/Sidebar";
import GroupageList from "./pages/groupage/GroupageList";
import ServiceList from "./pages/service/ServiceList";
import { ServiceProvider } from "./context/service/ServiceContext";
import ServiceNew from "./pages/service/ServiceNew";
import { ServiceNewProvider } from "./context/service/ServiceNewContext";
import { ServiceInfosProvider } from "./context/service/ServiceInfosContext";
import ServiceInfos from "./pages/service/ServiceInfos";
import { ServiceEditProvider } from "./context/service/ServiceEditContext";
import ServiceEdit from "./pages/service/ServiceEdit";
import { GroupageProvider } from "./context/groupage/GroupageContext";
import { GroupageInfosProvider } from "./context/groupage/GroupageInfosContext";
import GroupageInfos from "./pages/groupage/GroupageInfos";
import { GroupageEditProvider } from "./context/groupage/GroupageEditContext";
import GroupageEdit from "./pages/groupage/GroupageEdit";
import { GroupageNewProvider } from "./context/groupage/GroupageNewContext";
import GroupageNew from "./pages/groupage/GroupageNew";
import { PackageProvider } from "./context/package/PackageContext";
import PackageList from "./pages/package/PackageList";
import { PackageNewProvider } from "./context/package/PackageNewContext";
import PackageNew from "./pages/package/PackageNew";
import { PackageInfosProvider } from "./context/package/PackageInfosContext";
import PackageInfos from "./pages/package/PackageInfos";
import { PackageEditProvider } from "./context/package/PackageEditContext";
import PackageEdit from "./pages/package/PackageEdit";

function App() {
  const {authenticatedUser} = useContext(AuthenticationContext)
  return (
    <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
      <ToastContainer />

      {
        authenticatedUser &&
          <>
            <Sidebar />
            <div className="body-wrapper">
              <Header />

              <div className="container-fluid">
                <Routes>
                  <Route exact path="/" element={<Home />} />
                  <Route exact path="/groupages" element={<GroupageProvider><GroupageList /></GroupageProvider>} />
                  <Route exact path="/groupages/:idGroupage/infos" element={<GroupageInfosProvider><GroupageInfos/></GroupageInfosProvider>} />
                  <Route exact path="/groupages/:idGroupage/edit" element={<GroupageEditProvider><GroupageEdit /></GroupageEditProvider>} />
                  <Route exact path="/services" element={<ServiceProvider><ServiceList /></ServiceProvider>} />
                  <Route exact path="/services/new" element={<ServiceNewProvider><ServiceNew /></ServiceNewProvider>}/>
                  <Route exact path="/services/:idService/infos" element={<ServiceInfosProvider><ServiceInfos /></ServiceInfosProvider>}/>
                  <Route exact path="/services/:idService/edit" element={<ServiceEditProvider><ServiceEdit /></ServiceEditProvider>}/>
                  <Route exact path="/services/:idService/groupages/new" element={<GroupageNewProvider><GroupageNew/></GroupageNewProvider>}/>
                  <Route exact path="/groupages/:idGroupage/packages" element={<PackageProvider><PackageList/></PackageProvider>} />
                  <Route exact path="/groupages/:idGroupage/packages/new" element={<PackageNewProvider><PackageNew/></PackageNewProvider>} />
                  <Route exact path="/packages/:idPackage/infos" element={<PackageInfosProvider><PackageInfos /></PackageInfosProvider>} />
                  <Route exact path="/packages/:idPackage/edit" element={<PackageEditProvider><PackageEdit /></PackageEditProvider>} />
                </Routes>
              </div>
            </div>
          </>
      }

      <Routes>
        <Route exact path="/login" element={<LoginProvider><Login /></LoginProvider>} />
      </Routes>
    </div>
  );
}

export default App;
