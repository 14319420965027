import React from 'react'

const GroupageTableHead = () => {
  return (
    <thead className="text-dark fs-4">
        <tr>
            <th className="border-bottom-0 text-start">
                <h6 className="fw-semibold mb-0">Label</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Destination</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Date de départ</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Durée</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Prix</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Statut</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Actions</h6>
            </th>
        </tr>
    </thead>
  )
}

export default GroupageTableHead