import React, { useContext } from 'react'
import PackageContext from '../../context/package/PackageContext'

const PackageTableBody = () => {
    const {packages} = useContext(PackageContext)
  return (
    <tbody>
        {
            packages.map(
                (paquet) =>
                    <tr key={paquet.id}>
                        <td className="border-bottom-0">
                            <h6 className="fw-semibold mb-0">{paquet.label}</h6>
                        </td>
                        <td className="border-bottom-0">
                            <h6 className="fw-semibold mb-1">{paquet.weight}</h6>
                        </td>
                        <td className="border-bottom-0">
                            <h6 className="mb-0 fw-semibold">{paquet.price}</h6>
                        </td>
                        <td className="border-bottom-0">
                            <div className="d-flex justify-content-center align-items-center gap-2">
                                {paquet.paymentStatus === true ? <span className="badge bg-success rounded-3 fw-semibold">Payé</span> : <span className="badge bg-danger rounded-3 fw-semibold">Non payé</span>}
                            </div>
                        </td>
                        <td className="border-bottom-0">
                            <div className="d-flex justify-content-center align-items-center gap-2">
                                <span className="badge bg-success rounded-3 fw-semibold">{paquet.status}</span>
                            </div>
                        </td>
                        <td className="border-bottom-0">
                            <h6 className="mb-0 fw-semibold">{paquet.createdAt.slice(0,10)}</h6>
                        </td>
                        <td className="border-bottom-0 d-flex justify-content-evenly">
                            <a href={`/packages/${paquet.id}/infos`} className='btn-secondary btn'><i className="ti ti-eye"></i></a>
                            <a href={`/packages/${paquet.id}/edit`} className='btn-warning btn'><i className="ti ti-edit"></i></a>
                        </td>
                    </tr>
            )
        }
    </tbody>
  )
}

export default PackageTableBody