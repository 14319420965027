import React, { useContext } from 'react'
import ServiceEditContext from '../../context/service/ServiceEditContext'

const ServiceEdit = () => {
    const {
        handleEditService,
        id,
        destination, setDestination,
        duration, setDuration,
        unit, setUnit,
        price, setPrice,
        reduction, setReduction,
        address, setAddress,
        label, setLabel,
        description, setDescription,
        contact, setContact,
        statut, setStatut,
    } = useContext(ServiceEditContext)

  return (
    <div className="card">
        <div className="card-body">
            <h5 className="card-title fw-semibold mb-4">Nouveau service</h5>
            <div className="card">
                <div className="card-body">
                    <form className="row" onSubmit={(e) => handleEditService(e)}>
                        <div className="mb-3">
                            <label htmlFor="destination" className="form-label">Destination</label>
                            <input type="text" className="form-control" id="destination" value={destination} onChange={(e) => setDestination(e.target.value)} aria-describedby="destination"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="label" className="form-label">Label</label>
                            <select className="form-select" value={label} onChange={(e) => setLabel(e.target.value)} aria-describedby="label">
                                <option value="AIR CARGO">AIR_CARGO</option>
                                <option value="AIR_CARGO_WITHOUT_CUSTOM_CLEARANCE">AIR_CARGO_WITHOUT_CUSTOM_CLEARANCE</option>
                                <option value="MCO_WITHOUT_CUSTOM_CLEARANCE">MCO_WITHOUT_CUSTOM_CLEARANCE</option>
                                <option value="MCO">MCO</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="adresse" className="form-label">Adresse du cargo</label>
                            <input type="text" className="form-control" id="adresse" value={address} onChange={(e) => setAddress(e.target.value)} aria-describedby="adresse"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="contact" className="form-label">Contact</label>
                            <input type="text" className="form-control" id="contact" value={contact} onChange={(e) => setContact(e.target.value)} aria-describedby="contact"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="temps_moyen" className="form-label">Temps moyen</label>
                            <input type="number" min={1} className="form-control" id="temps_moyen" value={duration} onChange={(e) => setDuration(e.target.value)} aria-describedby="temps_moyen" placeholder="1"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="unit" className="form-label">Unité</label>
                            <input type="text" className="form-control" id="unit" value={unit} onChange={(e) => setUnit(e.target.value)} aria-describedby="unit"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="price" className="form-label">Prix</label>
                            <input type="number" min={1} className="form-control" id="price" value={price} onChange={(e) => setPrice(e.target.value)} aria-describedby="price" placeholder="1"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="reducted_price" className="form-label">Reduction</label>
                            <input type="number" min={1} className="form-control" id="reducted_price" value={reduction} onChange={(e) => setReduction(e.target.value)} aria-describedby="reducted_price" placeholder="1"/>
                        </div>
                        {/* <div className="mb-3">
                            <label htmlFor="image" className="form-label">Image de couverture</label>
                            <input type="file" multiple={false} accept='image/*' className="form-control" id="image" aria-describedby="image"/>
                        </div> */}
                        <div className="mb-3">
                            <label htmlFor="statut" className="form-label">Statut</label>
                            <input type="text" className='form-control' id='statut' list='statut-list' value={statut} onChange={(e) => setStatut(e.target.value)} aria-describedby="statut" />
                            <datalist id='statut-list'>
                                <select className="form-select" id="statut-opt" >
                                    <option value="ACTIVE">ACTIVE</option>
                                    <option value="DELETED">DELETED</option>
                                </select>
                            </datalist>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="description" className='form-label'>Description</label>
                            <textarea name="description" id="description" rows="10" className='form-control' value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                        </div>
                        <div className="d-flex gap-3">
                            <a href={`/services/${id}/infos`} type="button" className="btn btn-danger">Annuler</a>
                            <button type="submit" className="btn btn-primary">Modifier</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ServiceEdit