import React, { useContext } from 'react'
import GroupageInfosContext from '../../context/groupage/GroupageInfosContext'

const GroupageInfos = () => {
    const {groupage} = useContext(GroupageInfosContext)
  return (
    <div className="card">
        <div className="card-body">
            <div className='d-flex justify-content-between align-items-center p-3'>
                <h5 className="card-title fw-semibold mb-4">Informations du groupage</h5>
                <a href={`/groupages/${groupage.id}/packages`} className='btn btn-primary'><i className="ti ti-eye me-2"></i> Paquets</a>
            </div>
            
            <div className="card">
                <div className="card-body">
                    <form className="row">
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="label" className="form-label">Label</label>
                            <input type="text" className="form-control" id="label" value={groupage.label} aria-describedby="label" disabled/>
                        </div>
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="destination" className="form-label">Destination</label>
                            <input type="text" className="form-control" id="destination" value={groupage.destination} aria-describedby="destination" disabled/>
                        </div>
                        <div className="mb-3  col-lg-6">
                            <label htmlFor="depart" className="form-label">Départ</label>
                            {groupage.expectedDepartureDay && <input type="text" className="form-control" id="depart" value={groupage.expectedDepartureDay.slice(0, 10)} aria-describedby="adresse" disabled/>}
                        </div>
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="duration" className="form-label">Durée</label>
                            <input type="number" min={1} className="form-control" id="duration" value={groupage.expectedDurationInDays} aria-describedby="duration" placeholder="1" disabled/>
                        </div>
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="unit" className="form-label">Unité</label>
                            <input type="text" className="form-control" id="unit" value={groupage.unit} aria-describedby="unit" disabled/>
                        </div>
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="price" className="form-label">Prix</label>
                            <input type="number" min={1} className="form-control" id="price" value={groupage.price} aria-describedby="price" placeholder="1" disabled/>
                        </div>
                    </form>
                </div>

                <div className="card-body p-4">
                    <div className="mb-4">
                    <h5 className="card-title fw-semibold">Historique</h5>
                    </div>
                    <ul className="timeline-widget mb-0 position-relative mb-n5 col-8">
                        {
                            groupage.statusList && groupage.statusList.map(
                                (statut) =>
                                    <li key={statut.status} className="timeline-item d-flex justify-content-evenly position-relative overflow-hidden">
                                        <div className="timeline-time text-dark flex-shrink-0 text-end">{statut.createdAt.slice(0, 10)}</div>
                                        <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                            <span className="timeline-badge border-2 border border-success flex-shrink-0 my-8"></span>
                                        </div>
                                        <div className="timeline-desc fs-3 text-dark mt-n1">{statut.status}</div>
                                    </li>
                            )
                        }
                    </ul>
                </div>

                <div className="d-flex gap-3 p-3 mt-5 border-top">
                    <a href='/groupages' type="button" className="btn btn-danger">Retour</a>
                    <a href={`/groupages/${groupage.id}/edit`} type="button" className="btn btn-secondary">Modifier</a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default GroupageInfos