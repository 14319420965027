import { createContext, useContext, useEffect, useState } from "react";
import AuthenticationContext from "../authentication/AuthenticationContext";
import api from "../../api/api";
import { Bounce, toast } from "react-toastify";

const ServiceContext = createContext({})

export const ServiceProvider = ({children}) => {
    const [services, setServices] = useState([])
    const {authenticationToken} = useContext(AuthenticationContext)

    useEffect(() => {
        api.get("admin/groupage/service/list", {
            headers : {
                Authorization : authenticationToken
            }
        }).then((res) => {
            setServices(res.data)
        }).catch((err) => {
            toast.error("Echec de la recupération de la liste des services", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        })
    }, [authenticationToken])

    return (
        <ServiceContext.Provider
            value={
                {
                    services
                }
            }
        >
            {children}
        </ServiceContext.Provider>
    )
}

export default ServiceContext