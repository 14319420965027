import React from 'react'
import image from '../../assets/images/construction.png'

const Home = () => {
  return (
    <div className='row'>
        <div className="col-12 d-flex justify-content-evenly align-items-center">
            <img src={image} alt="" />
            <h1>En construction</h1>
        </div>
    </div>
  )
}

export default Home