import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";

const AuthenticationContext = createContext({})

export const AuthenticationProvider = ({children}) => {
    const [authenticationToken, setAuthenticationToken] = useState(localStorage.getItem('authenticationToken'))
    const [authenticatedUser, setAuthenticatedUser] = useState(JSON.parse(localStorage.getItem('authenticatedUser')))
    const navigate = useNavigate()

    useEffect(() => {
        if(!authenticatedUser){
            toast.warn("Veuillez vous connecter", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
            navigate('/login')
        }
    }, [authenticatedUser, navigate])

    const handleLogout = () => {
        localStorage.removeItem('authenticationToken')
        localStorage.removeItem('authenticatedUser')

        window.location.reload()
    }
    return (
        <AuthenticationContext.Provider
            value={
                {
                    authenticationToken, setAuthenticationToken,
                    authenticatedUser, setAuthenticatedUser,
                    handleLogout
                }
            }
        >
            {children}
        </AuthenticationContext.Provider>
    )
}

export default AuthenticationContext