import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import AuthenticationContext from "../authentication/AuthenticationContext";
import { Bounce, toast } from "react-toastify";

const ServiceEditContext = createContext({})

export const ServiceEditProvider = ({children}) => {
    const [id, setId] = useState('')
    const [destination, setDestination] = useState('')
    const [duration, setDuration] = useState(1)
    const [unit, setUnit] = useState('')
    const [price, setPrice] = useState(1)
    const [reduction, setReduction] = useState(1)
    const [address, setAddress] = useState('')
    const [label, setLabel] = useState('')
    const [description, setDescription] = useState('')
    const [contact, setContact] = useState('')
    const [statut, setStatut] = useState('')

    const {authenticationToken} = useContext(AuthenticationContext)
    const {idService} = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        api.get(`admin/groupage/service?id=${idService}`, {
            headers : {
                Authorization : authenticationToken
            }
        }).then((res) => {
            setId(res.data.id)
            setDestination(res.data.destination)
            setDescription(res.data.description)
            setDuration(res.data.averageDurationInDays)
            setUnit(res.data.unit)
            setAddress(res.data.cargoAddress)
            setReduction(res.data.reducedPrice)
            setContact(res.data.contact)
            setLabel(res.data.label)
            setPrice(res.data.price)
            setStatut(res.data.status)

        }).catch((err) => {
            toast.error("Une erreur est survenue lors de la recupération du service. Veuillez reéssayer !!!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
            console.log(err)
        })
    }, [authenticationToken, idService])
    
    const handleEditService = (e) => {
        e.preventDefault()

        const data = {
            "id" : id,
            "destination" : destination,
            "averageDurationInDays" : duration,
            "unit" : unit,
            "price" : price,
            "reducedPrice" : reduction,
            "cargoAddress" : address,
            "label" : label,
            "description" : description,
            "contact" : contact,
            "status" : statut
        }

        console.log(data)

        api.put("admin/groupage/service", data, {
            headers : {
                Authorization : authenticationToken
            }
        }).then((res) => {
            toast.success("Service modifié avec succès", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })

            navigate(`/services/${idService}/infos`)
        }).catch((err) => {
            toast.error("Une erreur est survenue lors de la modification du service. Veuillez reéssayer !!!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
            console.log(err)
        })
    }

    return (
        <ServiceEditContext.Provider
            value={
                {
                    handleEditService,
                    id,
                    destination, setDestination,
                    duration, setDuration,
                    unit, setUnit,
                    price, setPrice,
                    reduction, setReduction,
                    address, setAddress,
                    label, setLabel,
                    description, setDescription,
                    contact, setContact,
                    statut, setStatut,
                }
            }
        >
            {children}
        </ServiceEditContext.Provider>
    )
}

export default ServiceEditContext