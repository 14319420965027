import React, { useContext } from 'react'
import PackageNewContext from '../../context/package/PackageNewContext'
import { useParams } from 'react-router-dom'

const PackageNew = () => {
    const {idGroupage} = useParams()
    const {
        handleNewPackage,
        label, setLabel,
        weight, setWeight,
        senderName, setSenderName,
        senderPhone, setSenderPhone,
        receiverName, setReceiverName,
        receiverPhone, setReceiverPhone,
    } = useContext(PackageNewContext)

  return (
    <div className="card">
        <div className="card-body">
            <h5 className="card-title fw-semibold mb-4">Nouveau paquet</h5>
            <div className="card">
                <div className="card-body">
                    <form className="row" onSubmit={(e) => handleNewPackage(e)}>
                        <div className="mb-3">
                            <label htmlFor="label" className="form-label">Label</label>
                            <input type="text" className="form-control" id="label" value={label} onChange={(e) => setLabel(e.target.value)} aria-describedby="label"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="weight" className="form-label">Poids</label>
                            <input type="number" className="form-control" id="weight" value={weight} min={1} step={0.1} onChange={(e) => setWeight(e.target.value)} aria-describedby="weight"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="senderName" className="form-label">Nom de l'expéditeur</label>
                            <input type="text" className="form-control" id="senderName" value={senderName} onChange={(e) => setSenderName(e.target.value)} aria-describedby="senderName"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="senderPhone" className="form-label">Contact de l'expéditeur</label>
                            <input type="text" className="form-control" id="senderPhone" value={senderPhone} onChange={(e) => setSenderPhone(e.target.value)} aria-describedby="senderPhone" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="receiverName" className="form-label">Nom du destinataire</label>
                            <input type="text" className="form-control" id="receiverName" value={receiverName} onChange={(e) => setReceiverName(e.target.value)} aria-describedby="receiverName"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="receiverPhone" className="form-label">Contact du destinataire</label>
                            <input type="text" className="form-control" id="receiverPhone" value={receiverPhone} onChange={(e) => setReceiverPhone(e.target.value)} aria-describedby="receiverPhone" />
                        </div>
                        
                        {/* <div className="mb-3">
                            <label htmlFor="image" className="form-label">Image de couverture</label>
                            <input type="file" multiple={false} accept='image/*' className="form-control" id="image" aria-describedby="image"/>
                        </div> */}
        
                        <div className="d-flex gap-3">
                            <a href={`/groupages/${idGroupage}/packages`} type="button" className="btn btn-danger">Annuler</a>
                            <button type="submit" className="btn btn-primary">Ajouter</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PackageNew