import { createContext, useContext, useEffect, useState } from "react";
import AuthenticationContext from "../authentication/AuthenticationContext";
import { useParams } from "react-router-dom";
import api from "../../api/api";
import { Bounce, toast } from "react-toastify";

const PackageContext = createContext({})

export const PackageProvider = ({children}) => {
    const {authenticationToken} = useContext(AuthenticationContext)
    const {idGroupage} = useParams()

    const [packages, setPackages] = useState([])
    const [groupage, setGroupage] = useState({})

    useEffect(() => {
        api.get(`admin/groupage/parcel/list?groupId=${idGroupage}`, {
            headers : {
                Authorization : authenticationToken
            }
        }).then((res) => {
            setPackages(res.data.content)
        }).catch((err) => {
            toast.error("Echec de la recupération de la liste des paquets", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        })
    }, [authenticationToken, idGroupage])

    useEffect(() => {
        api.get(`admin/groupage/group?id=${idGroupage}`, {
            headers : {
                Authorization : authenticationToken
            }
        }).then((res) => {
            setGroupage(res.data)
        }).catch((err) => {
            toast.error("Une erreur est survenue lors de la recupération du groupage. Veuillez reéssayer !!!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        })

    }, [authenticationToken, idGroupage])

    return (
        <PackageContext.Provider
            value={
                {
                    packages,
                    groupage
                }
            }
        >
            {children}
        </PackageContext.Provider>
    )
}

export default PackageContext