import React, { useContext } from 'react'
import GroupageNewContext from '../../context/groupage/GroupageNewContext'

const GroupageNew = () => {
    const {
        handleNewGroupage,
        expectedDepartureDay, setExpectedDepartureDay,
        expectedDurationInDays, setExpectedDurationInDays,
        discount, setDiscount,
        idService, service
    } = useContext(GroupageNewContext)

  return (
    <div className="card">
        <div className="card-body">
            <h5 className="card-title fw-semibold mb-4">Nouveau groupage</h5>
            <div className="card">
                <div className="card-body">
                    <form className="row" onSubmit={(e) => handleNewGroupage(e)}>
                        <div className="mb-3">
                            <label htmlFor="label" className="form-label">Type</label>
                            <input type="text" className="form-control" id="label" value={service.label} aria-describedby="label" disabled/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="destination" className="form-label">Destination</label>
                            <input type="text" className="form-control" id="destination" value={service.destination} aria-describedby="label" disabled/>
                        </div>
                        <div className="mb-3 ">
                            <label htmlFor="departure" className="form-label">Départ</label>
                            <input type="date" className="form-control" id="departure" value={expectedDepartureDay} onChange={(e) => setExpectedDepartureDay(e.target.value)} aria-describedby="destination" />
                        </div>
                        
                        <div className="mb-3 ">
                            <label htmlFor="duration" className="form-label">Nombre de jours</label>
                            <input type="number" min={1} className="form-control" id="duration" value={expectedDurationInDays} onChange={(e) => setExpectedDurationInDays(e.target.value)} aria-describedby="duration" placeholder="1" />
                        </div>
                        <div className="mb-3 ">
                            <label htmlFor="discount" className="form-label">Reduction</label>
                            <input type="number" min={0} className="form-control" id="discount" value={discount} onChange={(e) => setDiscount(e.target.value)} aria-describedby="discount" placeholder="0" />
                        </div>

                        <div className="d-flex gap-3 p-3 mt-3 border-top">
                            <a href={`/services/${idService}/infos`} type="button" className="btn btn-danger">Retour</a>
                            <button type="submit" className="btn btn-secondary">Ajouter</button>
                        </div> 
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default GroupageNew