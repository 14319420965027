import React, { useContext } from 'react'
import PackageInfosContext from '../../context/package/PackageInfosContext'

const PackageInfos = () => {
    const {paquet} = useContext(PackageInfosContext)
  return (
    <div className="card">
        <div className="card-body">
            <div className='d-flex justify-content-between align-items-center p-3'>
                <h5 className="card-title fw-semibold mb-4">Informations du paquet</h5>
            </div>
            
            <div className="card">
                <div className="card-body">
                    <form className="row">
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="label" className="form-label">Label</label>
                            <input type="text" className="form-control" id="label" value={paquet.label} aria-describedby="label" disabled/>
                        </div>
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="weight" className="form-label">Poids</label>
                            <input type="text" className="form-control" id="weight" value={paquet.weight} aria-describedby="weight" disabled/>
                        </div>
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="price" className="form-label">Prix</label>
                            <input type="text" className="form-control" id="price" value={paquet.price} aria-describedby="price" disabled/>
                        </div>
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="payment" className="form-label">Statut</label>
                            <input type="text" className="form-control" id="payment" value={paquet.paymentStatus ? 'Payé' : 'Non payé'} aria-describedby="payment" disabled/>
                        </div>
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="senderName" className="form-label">Nom de l'expéditeur</label>
                            <input type="text" className="form-control" id="senderName" value={paquet.senderName} aria-describedby="senderName" disabled/>
                        </div>
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="senderPhone" className="form-label">Contact de l'expéditeur</label>
                            <input type="text" className="form-control" id="senderPhone" value={paquet.senderPhone} aria-describedby="senderPhone" disabled />
                        </div>
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="receiverName" className="form-label">Nom du destinataire</label>
                            <input type="text" className="form-control" id="receiverName" value={paquet.receiverName} aria-describedby="receiverName" disabled/>
                        </div>
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="receiverPhone" className="form-label">Contact du destinataire</label>
                            <input type="text" className="form-control" id="receiverPhone" value={paquet.receiverPhone} aria-describedby="receiverPhone" disabled />
                        </div>
                        <div className="mb-3  col-lg-6">
                            <label htmlFor="depart" className="form-label">Date d'arrivée</label>
                            {paquet.expectedArrivalDay && <input type="text" className="form-control" id="depart" value={paquet.expectedArrivalDay.slice(0, 10)} aria-describedby="adresse" disabled/>}
                        </div>
                    </form>
                </div>

                <div className="card-body p-4">
                    <div className="mb-4">
                    <h5 className="card-title fw-semibold">Historique</h5>
                    </div>
                    <ul className="timeline-widget mb-0 position-relative mb-n5 col-8">
                        {
                            paquet.statusList && paquet.statusList.map(
                                (statut) =>
                                    <li key={statut.status} className="timeline-item d-flex justify-content-evenly position-relative overflow-hidden">
                                        <div className="timeline-time text-dark flex-shrink-0 text-end">{statut.createdAt.slice(0, 10)}</div>
                                        <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                            <span className="timeline-badge border-2 border border-success flex-shrink-0 my-8"></span>
                                        </div>
                                        <div className="timeline-desc fs-3 text-dark mt-n1">{statut.status}</div>
                                    </li>
                            )
                        }
                    </ul>
                </div>

                <div className="d-flex gap-3 p-3 mt-5 border-top">
                    <a href={`/groupages/${paquet.groupId}/packages`} type="button" className="btn btn-danger">Retour</a>
                    <a href={`/packages/${paquet.id}/edit`} type="button" className="btn btn-secondary">Modifier</a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PackageInfos