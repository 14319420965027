
import React, { useContext } from 'react'
import ServiceInfosContext from '../../context/service/ServiceInfosContext'

const ServiceInfos = () => {
    const {service} = useContext(ServiceInfosContext)
  return (
    <div className="card">
        <div className="card-body">
            <div className='d-flex justify-content-between align-items-center border-bottom p-3'>
                <h5 className="card-title fw-semibold mb-4">Informations du service</h5>
                <a href={`/services/${service.id}/groupages/new`} className='btn btn-primary'><i className="ti ti-file-plus me-2"></i> Nouveau groupage</a>
            </div>
            <div className="card">
                <div className="card-body">
                    <form className="row">
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="destination" className="form-label">Destination</label>
                            <input type="text" className="form-control" id="destination" value={service.destination} aria-describedby="destination" disabled/>
                        </div>
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="label" className="form-label">Label</label>
                            <input type="text" className="form-control" id="label" value={service.label} aria-describedby="label" disabled/>
                        </div>
                        <div className="mb-3  col-lg-6">
                            <label htmlFor="adresse" className="form-label">Adresse du cargo</label>
                            <input type="text" className="form-control" id="adresse" value={service.cargoAddress} aria-describedby="adresse" disabled/>
                        </div>
                        <div className="mb-3  col-lg-6">
                            <label htmlFor="contact" className="form-label">Contact</label>
                            <input type="text" className="form-control" id="contact" value={service.contact} aria-describedby="contact" disabled/>
                        </div>
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="temps_moyen" className="form-label">Temps moyen</label>
                            <input type="number" min={1} className="form-control" id="temps_moyen" value={service.averageDurationInDays} aria-describedby="temps_moyen" placeholder="1" disabled/>
                        </div>
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="unit" className="form-label">Unité</label>
                            <input type="text" className="form-control" id="unit" value={service.unit} aria-describedby="unit" disabled/>
                        </div>
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="price" className="form-label">Prix</label>
                            <input type="number" min={1} className="form-control" id="price" value={service.price} aria-describedby="price" placeholder="1" disabled/>
                        </div>
                        <div className="mb-3 col-lg-6">
                            <label htmlFor="reducted_price" className="form-label">Reduction</label>
                            <input type="number" min={1} className="form-control" id="reducted_price" value={service.reducedPrice} aria-describedby="reducted_price" placeholder="1" disabled/>
                        </div>
                        {/* <div className="mb-3">
                            <label htmlFor="image" className="form-label">Image de couverture</label>
                            <input type="file" multiple={false} accept='image/*' className="form-control" id="image" aria-describedby="image"/>
                        </div> */}
                        <div className="mb-3  col-lg-6">
                            <label htmlFor="contact" className="form-label">Statut</label>
                            <input type="text" className="form-control" id="contact" value={service.status} aria-describedby="contact" disabled/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="description" className='form-label'>Description</label>
                            <textarea name="description" id="description" rows="5" className='form-control' value={service.description} disabled style={{resize: 'none'}}></textarea>
                        </div>
                        <div className="d-flex gap-3 border-top pt-3">
                            <a href='/services' type="button" className="btn btn-danger">Retour</a>
                            <a href={`/services/${service.id}/edit`} type="button" className="btn btn-secondary">Modifier</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ServiceInfos