import { createContext, useContext, useEffect, useState } from "react";
import AuthenticationContext from "../authentication/AuthenticationContext";
import { useParams } from "react-router-dom";
import api from "../../api/api";
import { Bounce, toast } from "react-toastify";

const PackageInfosContext = createContext({})

export const PackageInfosProvider = ({children}) => {
    const {authenticationToken} = useContext(AuthenticationContext)
    const {idPackage} = useParams()

    const [paquet, setPaquet] = useState({})

    useEffect(() => {
        api.get(`/admin/groupage/parcel?id=${idPackage}`, {
            headers : {
                Authorization : authenticationToken
            }
        }).then((res) => {
            setPaquet(res.data)
        }).catch((err) => {
            toast.error("Echec de la recupération des infos du paquet", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        })
    }, [authenticationToken, idPackage])

    return (
        <PackageInfosContext.Provider
            value={
                {
                    paquet
                }
            }
        >
            {children}
        </PackageInfosContext.Provider>
    )
}

export default PackageInfosContext