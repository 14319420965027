import React, { useContext } from 'react'
import ServiceContext from '../../context/service/ServiceContext'

const ServiceTableBody = () => {
    const {services} = useContext(ServiceContext)
  return (
    <tbody>
        {
            services.map(
                (service) =>
                    <tr key={service.id}>
                        <td className="border-bottom-0">
                            <h6 className="mb-0 fw-semibold">{service.label}</h6>
                        </td>
                        <td className="border-bottom-0">
                            <h6 className="fw-semibold mb-0">{service.destination}</h6>
                        </td>
                        <td className="border-bottom-0">
                            <h6 className="fw-semibold mb-1">{service.averageDurationInDays} jours</h6>
                        </td>
                        <td className="border-bottom-0">
                            <div className="d-flex justify-content-center align-items-center gap-2">
                                <span className="badge bg-success rounded-3 fw-semibold">{service.status}</span>
                            </div>
                        </td>
                        <td className="border-bottom-0 d-flex justify-content-evenly">
                            <a href={`/services/${service.id}/infos`} className='btn-secondary btn'><i className="ti ti-eye"></i></a>
                            <a href={`/services/${service.id}/edit`} className='btn-warning btn'><i className="ti ti-edit"></i></a>
                        </td>
                    </tr>
            )
        }
    </tbody>
  )
}

export default ServiceTableBody