import { createContext, useContext, useEffect, useState } from 'react'
import AuthenticationContext from '../authentication/AuthenticationContext'
import { useParams } from 'react-router-dom'
import api from '../../api/api'
import { Bounce, toast } from 'react-toastify'


const GroupageInfosContext = createContext({})

export const GroupageInfosProvider = ({children}) => {
    const {authenticationToken} = useContext(AuthenticationContext)
    const {idGroupage} = useParams()

    const [groupage, setGroupage] = useState({})

    useEffect(() => {
        api.get(`admin/groupage/group?id=${idGroupage}`, {
            headers : {
                Authorization : authenticationToken
            }
        }).then((res) => {
            setGroupage(res.data)
        }).catch((err) => {
            toast.error("Une erreur est survenue lors de la recupération du groupage. Veuillez reéssayer !!!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        })

    }, [authenticationToken, idGroupage])

    return (
        <GroupageInfosContext.Provider
            value={
                {
                    groupage
                }
            }
        >
            {children}
        </GroupageInfosContext.Provider>
    )
}

export default GroupageInfosContext